import app from '../../app';
import selectTags from '../../modals/selectTags';
import { flatWoods, getTreePath } from '../../helpers';
import selectInventory from '../../modals/selectInventory';

app.controller('requestSdsCtrl', [
  '$rootScope',
  '$scope',
  '$timeout',
  '$translate',
  'SdsRequestService',
  'CompanyService',
  'SpinnerService',
  'TagService',
  'InventoryService',
  'ModalService',
  'MessagesService',
  'ErrorService',
  function requestSdsCtrl(
    $rootScope,
    $scope,
    $timeout,
    $translate,
    SdsRequestService,
    CompanyService,
    SpinnerService,
    TagService,
    InventoryService,
    ModalService,
    MessagesService,
    ErrorService
  ) {
    const simpleCatch = ErrorService.simpleCatch;
    const vm = this;
    const defaultForm = [
      {
        name: 'from',
        type: 'email',
        text: $translate.instant('REQUEST.FORM_FIELDS.EMAIL'),
        isRequired: true
      },
      {
        name: 'Manufacturer',
        type: 'text',
        text: $translate.instant('REQUEST.FORM_FIELDS.MANUFACTURER'),
        isRequired: true
      },
      {
        name: 'Product Size',
        type: 'text',
        text: $translate.instant('REQUEST.FORM_FIELDS.SIZE'),
        isRequired: true
      },
      {
        name: 'Purchase Location',
        type: 'text',
        text: $translate.instant('REQUEST.FORM_FIELDS.PLACE_OF_PURCHASE'),
        isRequired: true
      },
      {
        name: 'Request Details',
        type: 'textarea',
        text: $translate.instant('REQUEST.FORM_FIELDS.ADDITIONAL_DETAILS'),
        isRequired: false
      },
      {
        name: 'file',
        type: 'file',
        isRequired: false
      }
    ];
    const sendRequest = SpinnerService.wrap(function (tagIds = [], inventoryIds = []) {
      return SdsRequestService.sendRequest(document.getElementById('requestForm'), {
        tagIds,
        inventoryIds
      });
    });
    let tagsWorkflowDropdown = null;
    let inventoryWorkflowDropdown = null;
    vm.fileTypeError = false;
    vm.files = {};
    vm.formModel = {};
    vm.tagsWorkflowModel = [];
    vm.inventoryWorkflowModel = [];
    vm.addSdsForm = [];
    vm.autoApproveSdsRequest = false;
    vm.selectedFiles = {};

    const updateInputFiles = function (index, updatedFiles) {
      const elementId = `field_${index}`;
      const inputElement = document.getElementById(elementId);

      const newInput = document.createElement('input');
      newInput.id = elementId;
      newInput.name = 'file';
      newInput.style.display = 'none';
      newInput.type = 'file';
      newInput.accept = 'application/pdf';
      newInput.multiple = true;

      newInput.setAttribute('uploader', 'ctrl.uploader');
      newInput.setAttribute('ng-model', `ctrl.formModel[${elementId}]`);
      newInput.setAttribute('ng-required', inputElement.getAttribute('ng-required'));
      newInput.setAttribute(
        'ng-attr-file-required',
        inputElement.getAttribute('ng-attr-file-required')
      );

      newInput.addEventListener('change', () => vm.fileChanged(index));

      const dataTransfer = new DataTransfer();
      updatedFiles.forEach(file => dataTransfer.items.add(file));

      newInput.files = dataTransfer.files;

      inputElement.replaceWith(newInput);
    };

    vm.fileChanged = function (index) {
      const fileInput = document.getElementById(`field_${index}`);
      const files = Array.from(fileInput.files || []);
      if (files.length) {
        vm.selectedFiles[index] = vm.selectedFiles[index].concat(files);
        updateInputFiles(index, vm.selectedFiles[index]);
        $timeout(() => $scope.$apply());
      }
    };

    vm.selectFile = function (index) {
      if (!vm.selectedFiles[index]) {
        vm.selectedFiles[index] = [];
      }
      $('input[id=field_' + index + ']').click();
    };

    vm.removeFile = function (fileIndex, partIndex) {
      vm.selectedFiles[partIndex].splice(fileIndex, 1);
      updateInputFiles(partIndex, vm.selectedFiles[partIndex]);
      $timeout(() => $scope.$apply());
    };

    vm.getFilename = function (elementId) {
      const fileInput = document.getElementById(elementId);

      return fileInput.files && fileInput.files.length ? fileInput.files[0].name : '';
    };

    vm.getFiles = function (elementId) {
      const fileInput = document.getElementById(elementId);

      return fileInput.files ? fileInput.files : [];
    };

    vm.onSubmit = async function () {
      let tagOptions = {};
      let tagIds = [];
      let inventoryIds = [];

      try {
        if (vm.autoApproveSdsRequest) {
          if (!$rootScope.companySettings.hideTagsWorkflowEnabled) {
            tagOptions = await ModalService.open(
              selectTags($rootScope.companyId, {
                showAndOrDropdown: false,
                saveButtonText: 'COMMON.ACTIONS.SEND'
              })
            );
            tagIds = (tagOptions.tags || []).map(tag => tag.id);
          }

          if ($rootScope.companySettings.inventoryInSearchEnabled) {
            inventoryIds = (
              await ModalService.open(
                selectInventory($rootScope.companyId)
              )
            ).map(result => result.id);
          }
        } else {
          if (tagsWorkflowDropdown) {
            if (tagsWorkflowDropdown.isRequired && !vm.tagsWorkflowModel.length)
              return MessagesService.warning('TAGS.SELECT_TAGS');

            tagIds = vm.tagsWorkflowModel.map(tag => tag.value);
          }

          if (inventoryWorkflowDropdown) {
            if (inventoryWorkflowDropdown.isRequired && !vm.inventoryWorkflowModel.length)
              return MessagesService.warning('INVENTORY.SELECT_ADD');

            inventoryIds = vm.inventoryWorkflowModel.map(el => el.value);
          }
        }
      } catch (err) {
        if (err && err !== 'backdrop click') throw err;

        return;
      }

      try {
        await sendRequest(tagIds, inventoryIds);
        vm.state = 'SENT';

        return MessagesService.success();
      } catch (err) {
        console.log(err);
        return simpleCatch(err);
      }
    };

    vm.resetForm = function () {
      vm.formModel = {};
      vm.files = {};
      vm.state = null;
      vm.selectedFiles = {};
    };

    const init = SpinnerService.wrap(function () {
      return CompanyService.getCurrentCompanyPromise()
        .$promise.then(function () {
          return CompanyService.CompanySettings($rootScope.companyId).$promise;
        })
        .then(function () {
          vm.autoApproveSdsRequest =
            $rootScope.currentUser &&
            $rootScope.currentUser.autoApproveSdsRequest &&
            $rootScope.accessAddTags();

          if ($rootScope.companySettings.addSdsFormEnabled) {
            vm.addSdsForm = $rootScope.companySettings.addSdsForm || [];
            initTagsWorkflow();
            initInventoryWorkflow();
          } else {
            vm.addSdsForm = defaultForm;
          }
        });
    });

    init();

    async function initTagsWorkflow() {
      const companyId = $rootScope.companyId;
      tagsWorkflowDropdown = vm.addSdsForm.find(
        field => field.tagsWorkflow && field.type === 'dropdown'
      );

      if (!tagsWorkflowDropdown) return;

      await TagService.getCompanyTags(companyId);
      const tags = flatWoods(TagService.companyTagsTree[companyId], 'tags').filter(
        tag => !tag.tags || !tag.tags.length
      );
      tagsWorkflowDropdown.values = tags.reduce((sum, tag) => {
        if (tag.archived || tag.restricted) return sum;

        return sum.concat({
          title: tag.path && tag.path.length ? tag.path.join(' / ') + ' / ' + tag.title : tag.title,
          value: tag.id
        });
      }, []);
    }

    async function initInventoryWorkflow() {
      const companyId = $rootScope.companyId;
      inventoryWorkflowDropdown = vm.addSdsForm.find(
        field => field.inventoryWorkflow && field.type === 'dropdown'
      );

      if (!inventoryWorkflowDropdown) return;

      const items = await InventoryService.getTreeInfo(companyId);

      inventoryWorkflowDropdown.values = Object.values(items).reduce((sum, inv) => {
        if (!inv.data) return sum;
        return sum.concat({
          title: inv.path && inv.path.length ? inv.path.replace('/', ' / ') : inv.data.name,
          value: inv.data.id
        });
      }, []);
    }
  }
]);
