import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';

app.directive('productElement', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      scope: {
        product: '='
      },
      templateUrl: require('./productElement.html'),
      controllerAs: 'vm',
      controller: function ($scope, ProductService, ModalService) {
        const vm = this;
        vm.showPrivate = $rootScope.checkIfUserIs('admin');
        vm.companiesById = {};
        vm.getProductSize = ProductService.showCorrectProductSize;
        vm.openSupplierInfoModal = function (product) {
          return ModalService.open(supplierInfo({ suppliers: product.suppliers }));
        };
        init();

        async function init() {
          if (vm.showPrivate && $rootScope.companies) {
            await $rootScope.companies;
            $rootScope.companies.forEach(c => {
              vm.companiesById[c.id] = c;
            });
            $scope.$apply();
          }
        }
      }
    };
  }
]);
