const { localVersion } = require('../common/helpers/index');

const companyDomains = {
  tdsb: ['tdsb.canadasds.com', 'tdsb.ussds.com', 'tdsb.localhost.com'],
  login: ['login.ussds.com'],
  rdsb: ['rdsb.canadasds.com'],
  ddsb: ['sds.ddsb.ca'],
  ugdsb: ['ugdsb.canadasds.com'],
  peelsb: ['peelsb.canadasds.com'],
  hdsb: ['hdsb.canadasds.com'],
  sccdsb: ['sccdsb.canadasds.com'],
  sarnia: ['sarnia.canadasds.com'],
  demo: ['demo.ussds.com', 'demo.canadasds.com'],
  test: [
    'test.ussds.com',
    'test.canadasds.com',
    'stage.ussds.com',
    'stage.canadasds.com',
    'local.canadasds.com',
    'login.canadasds.com',
    'test.localhost.com',
    'admin.localhost.com'
  ],
  demo2: ['demo2.canadasds.com', 'demo2.ussds.com'],
  pvnccdsb: ['pvnccdsb.canadasds.com'],
  wecdsb: ['wecdsb.canadasds.com'],
  lkdsb: ['lkdsb.canadasds.com'],
  ycdsb: ['ycdsb.canadasds.com'],
  smcdsb: ['smcdsb.canadasds.com'],
  ottawa: ['ottawa.canadasds.com'],
  scdsb: ['scdsb.canadasds.com'],
  ldsb: ['ldsb.canadasds.com'],
  bc: ['bc.canadasds.com'],
  vsb: ['vsb.canadasds.com'],
  cavanagh: ['cavanagh.canadasds.com'],
  sd53: ['sd53.canadasds.com'],
  sd74: ['sd74.canadasds.com'],
  sd62: ['sd62.canadasds.com'],
  amdsb: ['amdsb.canadasds.com'],
  sd27: ['sd27.canadasds.com'],
  sd69: ['sd69.canadasds.com'],
  sd33: ['sd33.canadasds.com'],
  puhsd: ['puhsd.ussds.com'],
  ptc: ['ptc.ussds.com'],
  cps: ['cpsenergy.ussds.com'],
  sd6: ['sd6.canadasds.com'],
  hpcdsb: ['hpcdsb.canadasds.com'],
  dpcdsb: ['dpcdsb.canadasds.com'],
  sd19: ['sd19.canadasds.com'],
  esd: ['esd.ussds.com'],
  wcc: ['wcc.ussds.com'],
  brcc: ['brcc.ussds.com'],
  rrdsb: ['rrdsb.canadasds.com'],
  yrdsb: ['yrdsb.canadasds.com'],
  epsb: ['epsb.canadasds.com'],
  sd5: ['sd5.canadasds.com'],
  lcsd2: ['lcsd2.ussds.com'],
  sd54: ['sd54.canadasds.com'],
  ccsd: ['ccsd.ussds.com'],
  ldcsb: ['ldcsb.canadasds.com'],
  kingphilip: ['kingphilip.ussds.com'],
  sncdsb: ['sncdsb.canadasds.com'],
  hpedsb: ['hpedsb.canadasds.com'],
  eips: ['eips.canadasds.com'],
  sd37: ['sd37.canadasds.com'],
  sd79: ['sd79.canadasds.com'],
  norfolk: ['norfolk.ussds.com'],
  davis: ['davis.ussds.com'],
  bgcdsb: ['bgcdsb.canadasds.com'],
  sd51: ['sd51.canadasds.com'],
  conestoga: ['conestoga.canadasds.com'],
  hwcdsb: ['hwcdsb.canadasds.com'],
  msba: ['msba.canadasds.com'], // company for demo
  rrvsd: ['rrvsd.canadasds.com'], // company for demo
  pembinatrails: ['pembinatrails.canadasds.com'], // company for demo
  brampton: ['brampton.canadasds.com'],
  wsd: ['wsd.ussds.com'],
  sd73: ['sd73.canadasds.com'],
  'tri-county': ['tri-county.ussds.com'],
  bpsd: ['bpsd.canadasds.com'],
  blsd: ['blsd.canadasds.com'],
  bsd: ['bsd.canadasds.com'],
  dsfm: ['dsfm.canadasds.com'],
  'esd-ca': ['esd.canadasds.com'], // Evergreen School Division (MSBA child)
  ffsd: ['ffsd.canadasds.com'],
  flbsd: ['flbsd.canadasds.com'],
  fsd: ['fsd.canadasds.com'],
  gvsd: ['gvsd.canadasds.com'],
  hsd: ['hsd.canadasds.com'],
  isd: ['isd.canadasds.com'],
  ksd: ['ksd.canadasds.com'],
  lakeshoresd: ['lakeshoresd.canadasds.com'],
  lssd: ['lssd.canadasds.com'],
  mitt: ['mitt.canadasds.com'],
  lrsd: ['lrsd.canadasds.com'],
  mvsd: ['mvsd.canadasds.com'],
  mystery: ['mystery.canadasds.com'],
  pwsd: ['pwsd.canadasds.com'],
  pcsd: ['pcsd.canadasds.com'],
  plpsd: ['plpsd.canadasds.com'],
  prsd: ['prsd.canadasds.com'],
  pssd: ['pssd.canadasds.com'],
  retsd: ['retsd.canadasds.com'],
  rrsd: ['rrsd.canadasds.com'],
  srsd: ['srsd.canadasds.com'],
  '7oaks': ['7oaks.canadasds.com'],
  shmb: ['shmb.canadasds.com'],
  sjasd: ['sjasd.canadasds.com'],
  sunrise: ['sunrise.canadasds.com'],
  svsd: ['svsd.canadasds.com'],
  tmsd: ['tmsd.canadasds.com'],
  trsd: ['trsd.canadasds.com'],
  westernsd: ['westernsd.canadasds.com'],
  whiteshell: ['whiteshell.canadasds.com'],
  'wsd-ca': ['wsd.canadasds.com'], // Winnipeg School Division (MSBA child)
  lcsd: ['lcsd.ussds.com'],
  canyonsdistrict: ['canyonsdistrict.ussds.com'],
  sd57: ['sd57.canadasds.com'],
  ssm: ['ssm.canadasds.com'],
  winnipeg: ['winnipeg.canadasds.com'],
  gedsb: ['gedsb.canadasds.com'],
  '3shealth': ['3shealth.canadasds.com'],
  saskcancer: ['saskcancer.canadasds.com'],
  sha: ['sha.canadasds.com'],
  altona: ['altona.canadasds.com'],
  rhineland: ['rhineland.canadasds.com'],
  sd8: ['sd8.canadasds.com'],
  mfnerc: ['mfnerc.canadasds.com'],
  hscdsb: ['hscdsb.canadasds.com'],
  sd20: ['sd20.canadasds.com'],
  uah: ['uah.ussds.com'],
  monmouth: ['monmouth.ussds.com'],
  dcdsb: ['dcdsb.canadasds.com'],
  cityofnb: ['cityofnb.canadasds.com'],
  sd60: ['sd60.canadasds.com'],
  torrancecounty: ['torrancecounty.ussds.com'],
  elementalenzymes: ['elementalenzymes.ussds.com'],
  chemtrade: ['chemtrade.canadasds.com'],
  countypaintearth: ['countypaintearth.canadasds.com'],
  arterra: ['arterra.canadasds.com'],
  isd31: ['isd31.ussds.com'],
  bwdsb: ['bwdsb.canadasds.com'],
  sheridan: ['sheridan.canadasds.com'],
  northgateindustries: ['northgateindustries.canadasds.com'],
  'cn-bus': ['cn-bus.ussds.com'],
  rekointl: ['rekointl.canadasds.com'],
  globalhazmat: ['globalhazmat.canadasds.com'],
  bchu: ['bchu.canadasds.com'],
  richmondhill: ['richmondhill.canadasds.com'],
  natcoat: ['natcoat.ussds.com'],
  benike: ['benike.ussds.com'],
  rgrta: ['rgrta.ussds.com'],
  sgdsb: ['sgdsb.canadasds.com'],
  bcldb: ['bcldb.canadasds.com'],
  ornge: ['ornge.canadasds.com'],
  universaloil: ['universaloil.ussds.com'],
  internal: ['internal.canadasds.com', 'internal.ussds.com'],
  northerncollege: ['northerncollege.canadasds.com'],
  diamondaircraft: ['diamondaircraft.canadasds.com'],
  niagaracollege: ['niagaracollege.canadasds.com'],
  alachuacounty: ['alachuacounty.ussds.com'],
  johnbrooks: ['johnbrooks.canadasds.com'],
  brainerdchemical: ['brainerdchemical.ussds.com'],
  howardwinn: ['howardwinn.ussds.com'],
  hrh: ['hrh.canadasds.com'],
  libertyarc: ['libertyarc.ussds.com'],
  walkeremulsions: ['walkeremulsions.canadasds.com']
};

module.exports.companyDomains = companyDomains;
module.exports.getCompanyDomainByName = function (name) {
  const _name = name.toLowerCase();
  const websiteLocalVersion = localVersion().toLowerCase();
  const domains = companyDomains[`${_name}-${websiteLocalVersion}`] || companyDomains[_name];

  if (domains.length === 1) return domains[0];

  let mainDomainName = 'canadasds';
  switch (websiteLocalVersion) {
    case 'ca':
      mainDomainName = 'canadasds';
      break;
    case 'us':
      mainDomainName = 'ussds';
      break;
  }

  return domains.find(domainName => domainName.includes(mainDomainName));
};
