import app from '../../app';

app.directive('companySelectButton', [
  '$q',
  function ($q) {
    return {
      restrict: 'E',
      templateUrl: require('./companySelectButton.html'),
      scope: {
        onCompanySelected: '=',
        onSearch: '=',
      },
      controllerAs: 'ctrl',
      controller: function ($rootScope, $scope) {
        const vm = this;
        vm.companies = [];
        vm.selectedCompany = null;
        init();

        async function init() {
          //init
          vm.companies = await $rootScope.companies;
        }

        vm.onSearch = function () {
          $scope.onSearch && $scope.onSearch(vm.selectedCompany);
        }

        vm.selectCompany = function (company) {
          vm.selectedCompany = company;
          $scope.onCompanySelected && $scope.onCompanySelected(company);
        }
      }
    };
  }
]);
