import angular from 'angular';
import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';
import { clearObject } from '../../helpers';

app.directive('createProduct', [
  '$rootScope',
  'ProductService',
  'ManufacturerService',
  'MeasureService',
  'ModalService',
  function (
    $rootScope,
    ProductService,
    ManufacturerService,
    MeasureService,
    ModalService
  ) {
    return {
      restrict: 'E',
      templateUrl: require('./createProduct.html'),
      scope: {
        onCreated: '=',
        manufacturers: '=',
        product: '=',
        hideAddButton: '=',
        addNewProduct: '=',
        newProductForm: '='
      },
      link: function (scope) {
        scope.sizeTypes = ProductService.sizeTypes;
        scope.noSdsReasons = ProductService.noSdsReasons;
        scope.selectedSizeTypeId = scope.sizeTypes.specific.id;
        scope.selectedNoSdsReasonId = scope.noSdsReasons.nonRegulated.id;
        scope.sdsRequiredDisabled =
          !!scope.product.sdsRequiredDisabled || !$rootScope.accessProductSDSOption();
        scope.newProductForm = scope.newProduct_form;
        scope.measureUnits = [];
        scope.privateCompanyId = null;
        scope.privateProduct = null;
        scope.companiesById = {};
        scope.tooglePrivateProduct = privateProduct => {

          scope.privateProduct = privateProduct;
        }

        scope.privateCompanySelected = function (company) {
          scope.privateCompanyId = company.id;
          scope.$apply();
        }
        scope.onSelectSizeType = function (product) {
          switch (scope.selectedSizeTypeId) {
            case scope.sizeTypes.specific.id:
              delete product.customSize;
              break;

            case scope.sizeTypes.general.id:
              delete product.size;
              delete product.customSize;
              break;

            case scope.sizeTypes.custom.id:
              delete product.size;
              delete product.measureId;
              break;
          }
        };

        scope.addNewProduct = function () {
          const product = scope.product;
          const form = scope.newProduct_form;

          product.sizeType = scope.selectedSizeTypeId;
          if (!$rootScope.checkIfUserIs('admin')) {
            product.privateCompanyId = $rootScope.companyId;
          } else if (scope.privateProduct && scope.privateCompanyId) {
            product.privateCompanyId = scope.privateCompanyId;
          }
          if (!product.sdsRequired) {
            product.noSdsReason = scope.selectedNoSdsReasonId;
          }

          ProductService.create(product).then(function (createdProduct) {
            form.$setUntouched();
            form.$setPristine();

            clearObject(product);
            product.sdsRequired = true;

            scope.onCreated && scope.onCreated(createdProduct);
          });
        };

        scope.openSupplierInfoModal = function () {
          return ModalService.open(
            supplierInfo({ suppliers: scope.product.suppliers, editable: true })
          ).then(suppliers => {
            scope.product.suppliers = suppliers;
          });
        };

        init();

        async function init() {
          MeasureService.getMeasureUnits().then(prepareMeasureSelect);

          if (!scope.manufacturers) {
            ManufacturerService.manufacturersPromise().then(prepareManufacturerSelect);
          } else {
            prepareManufacturerSelect(scope.manufacturers);
          }
          if ($rootScope.checkIfUserIs('admin')) {
            await $rootScope.companies;
            $rootScope.companies.forEach(c => {
              scope.companiesById[c.id] = c;
            });
          }
        }

        function prepareMeasureSelect(measureUnits) {
          scope.measureUnits = scope.measureUnits.concat(
            {
              title: 'COMMON.LABELS.NONE',
              id: undefined
            },
            measureUnits
          );
        }

        function prepareManufacturerSelect(manufacturers) {
          scope.manufacturers = angular.extend(
            {
              undefined: {
                name: 'PRODUCTS.FORM.SELECT_MANUFACTURER',
                id: undefined
              }
            },
            manufacturers
          );
        }
      }
    };
  }
]);
