import angular from 'angular';

export default function addProductSdsModal(props) {
  return {
    templateUrl: require('./addProductSds.html'),
    keyboard: true,
    backdrop: true,
    controller: [
      '$uibModalInstance',
      '$translate',
      'SolrDocument',
      'Product',
      'ManufacturerService',
      function ($uibModalInstance, $translate, SolrDocument, Product, ManufacturerService) {
        const vm = this,
          maxDocsNum = 100,
          minSearchStringLength = 3;

        vm.cancel = $uibModalInstance.dismiss;
        vm.isLoading = null;
        vm.docs = [];
        vm.manufacturers = [];
        vm.searchByManufacturer = null;
        vm.productCompanies = []

        const dataPromise = Promise.all([
          ManufacturerService.manufacturersPromise(),
          $translate('SEARCH.SEARCH_ALL'),
          Product.companies({ id: props.product.id }).$promise.then(res => {
            vm.productCompanies = res.map(company => company.id);
          })
        ]).then(res => {
          const defaultValue = { id: '*', name: res[1] };
          vm.manufacturers = [defaultValue, ...Object.values(res[0])];
          vm.searchByManufacturer = defaultValue;
        });

        vm.select = function (sds) {
          const documentModel = {
              id: sds.id,
              products: {
                set: _.uniq([...(sds.products || []), props.product.id])
              },
              companyId: {
                set: _.uniq([
                  ...vm.productCompanies,
                  ...(sds.companyId || []),
                  ...(props.companyId || []),
                  ...(props.product.inventoriedInCompanies || [])
                ])
              }
            },
            solrData = { data: '[' + JSON.stringify(documentModel) + ']' };

          Promise.all([
            Product.updateSdsId({ sdsId: sds.id }, { [props.product.id]: true }).$promise,
            SolrDocument.update(solrData)
          ]).then(() => SolrDocument.propagateToLinked({ id: sds.id }));

          $uibModalInstance.close(sds);
        };

        vm.searchSds = function (search) {
          if (search && search.length > minSearchStringLength) {
            let payload = {
              query: search,
              filters: [],
              fields: ['products'],
              limit: maxDocsNum,
              offset: 0,
            };
            if (vm.searchByManufacturer) {
              payload.filters.push('manufacturer:' + vm.searchByManufacturer.id);
            }
            vm.isLoading = true;
            Promise.all([
              SolrDocument.findSds(payload).$promise.then(function (resp) {
                vm.docs = resp.docs || [];
              }),
              // to be sure the data request is finished
              dataPromise
            ]).then(() => {
              vm.isLoading = false;
            });
          }
        }
      }
    ],
    controllerAs: 'ctrl'
  };
}
