import app from '../../app';
import scheduleReport from '../../modals/scheduleReport';
import { getOffset } from '../../helpers';
import selectTags from '../../modals/selectTags';
import downloadNotification from '../../modals/downloadNotification';

app.controller('sdsListByTagReportCtrl', [
  '$window',
  '$rootScope',
  '$scope',
  'CompanyFactory',
  'CompanyService',
  'SpinnerService',
  'HazardService',
  'SchedulerService',
  'ModalService',
  'SdsListByTagReport',
  'ErrorService',
  'MessagesService',
  function (
    $window,
    $rootScope,
    $scope,
    CompanyFactory,
    CompanyService,
    SpinnerService,
    HazardService,
    SchedulerService,
    ModalService,
    SdsListByTagReport,
    ErrorService,
    MessagesService
  ) {
    const vm = this;
    vm.service = $rootScope;
    vm.HazardService = HazardService;
    vm.totalItems = 0;

    $scope.allTagsSelected = false;
    $scope.selectedTagsResult = {};
    $scope.tagIds = [];
    $scope.email = '';

    function init() {
      vm.service.dataInitSDSListReport = true;
      vm.service.startFrom = 1;
      vm.service.PER_PAGE = 100;
      vm.service.companies = [];
      vm.service.data = { tags: [] };
      vm.service.selectedCompany = {};

      if ($rootScope.checkIfUserIs('admin')) {
        CompanyFactory.GetCompanies(function (companies) {
          vm.service.companies = companies;
        });
      } else {
        CompanyService.getCurrentCompanyPromise().$promise.then(function () {
          if ($rootScope.currentCompany.childSdsCompanies.length) return; // handle this by companySelector

          vm.onCompanySelected({ id: $rootScope.companyId });
        });
      }
    }

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (!vm.service.dataInitSDSListReport) {
        init();
      }
    });

    $scope.$watch('siteLanguage', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      vm.retrieve(vm.service.selectedCompany.id, vm.service.PER_PAGE, 0);
    });

    vm.retrieve = SpinnerService.wrap(function (companyId, limit, offset) {
      return SdsListByTagReport.find({
        companyId: companyId,
        limit: limit,
        offset: offset,
        lang: $rootScope.siteLanguage
      }).$promise.then(function (data) {
        vm.service.data = data;
      });
    });

    vm.handleGeneralExport = function () {
      vm.selectTags()
        .then(() => {
          vm.typeEmail()
            .then(() => {
              // $window.open('/api/sdsListByTagReport/export?companyId=' + vm.service.selectedCompany.id+'&lang=' + $rootScope.siteLanguage, '_blank');
              return SpinnerService.wrap(function () {
                console.log($scope.selectedTagsResult);
                console.log($scope.tagIds);
                console.log($scope.email);
                const payload = {
                  companyId: vm.service.selectedCompany.id,
                  tags: $scope.tagIds,
                  email: $scope.email,
                  lang: $rootScope.siteLanguage
                };
                return SdsListByTagReport.export(payload).$promise;
              })();
            })
            .then(() => {
              MessagesService.info('COMMON.MESSAGES.WILL_BE_NOTIFIED');
            });
        })
        .catch(error => {
          if (error === 'backdrop click') return;
          error && ErrorService.simpleCatch(error);
        });
    };

    vm.handleSplitExport = function () {
      vm.selectTags()
        .then(() => {
          vm.typeEmail()
            .then(() => {
              return SpinnerService.wrap(function () {
                console.log($scope.selectedTagsResult);
                console.log($scope.tagIds);
                console.log($scope.email);
                const payload = {
                  companyId: vm.service.selectedCompany.id,
                  tags: $scope.tagIds,
                  email: $scope.email,
                  lang: $rootScope.siteLanguage
                };
                return SdsListByTagReport.splitExport(payload).$promise;
              })();
            })
            .then(() => {
              MessagesService.info('COMMON.MESSAGES.WILL_BE_NOTIFIED');
            });
        })
        .catch(error => {
          if (error === 'backdrop click') return;
          error && ErrorService.simpleCatch(error);
        });
    };

    vm.handleUserFillableInventoryQuantityReport = function () {
      vm.selectTags()
        .then(() => {
          vm.typeEmail()
            .then(() => {
              return SpinnerService.wrap(function () {
                const payload = {
                  companyId: vm.service.selectedCompany.id,
                  tags: $scope.tagIds,
                  email: $scope.email,
                  lang: $rootScope.siteLanguage
                };
                return SdsListByTagReport.userFillableInventoryQuantityReport(payload).$promise;
              })();
            })
            .then(() => {
              MessagesService.info('COMMON.MESSAGES.WILL_BE_NOTIFIED');
            });
        })
        .catch(error => {
          if (error === 'backdrop click') return;
          error && ErrorService.simpleCatch(error);
        });
    };

    vm.handlePaginate = function () {
      vm.retrieve(
        vm.service.selectedCompany.id,
        vm.service.PER_PAGE,
        getOffset(vm.service.startFrom, vm.service.PER_PAGE)
      );
    };

    vm.onCompanySelected = function (selectedCompany) {
      vm.service.selectedCompany = selectedCompany;
      vm.service.startFrom = 1;

      vm.retrieve(vm.service.selectedCompany.id, vm.service.PER_PAGE, 0);
    };

    vm.onChangeFilterTag = function (tag) {
      vm.onCompanySelected();
    };

    vm.openScheduleModal = function () {
      return vm.selectTags().then(() => {
        ModalService.open(scheduleReport()).then(res => {
          if (
            $rootScope.checkIfUserIs('admin') &&
            (!vm.service.selectedCompany || !vm.service.selectedCompany.id)
          ) {
            return SchedulerService.createReportForAllCompanies(
              res,
              vm.service.companies,
              { tagIds: $scope.tagIds },
              createScheduledReport
            );
          } else {
            return createScheduledReport(res, $rootScope.companyId);
          }
        });
      });
    };

    vm.selectTags = function () {
      return ModalService.open(
        selectTags(vm.service.selectedCompany.id, {
          showAndOrDropdown: false,
          checkedTags: $scope.selectedTagsResult.tags || [],
          allowSearchByParentTags: false,
          message: 'TAGS.FILTER_BY_TAGS',
          saveButtonText: 'COMMON.ACTIONS.SELECT',
          allTagsSelected: true
        })
      ).then(result => {
        $scope.allTagsSelected = result.allSelected;
        $scope.selectedTagsResult = result;
        $scope.tagIds = (result.tags || []).map(tag => tag.id);
      });
    };

    vm.typeEmail = function () {
      return ModalService.open(downloadNotification('SDSLISTBYTAGREPORT')).then(result => {
        $scope.email = result.email;
      });
    };

    function createScheduledReport(formObj, companyId, params = {}, quiet = false) {
      params.lang = $rootScope.siteLanguage;
      params.tagIds = $scope.tagIds;
      return SchedulerService.createReport(
        SchedulerService.jobs.report.SDS_LIST_BY_TAG_REPORT,
        companyId,
        formObj.emails,
        formObj.frequency,
        params,
        quiet
      );
    }
  }
]);
