import angular from 'angular';
import app from '../../app';
import supplierInfo from '../../modals/supplierInfo';

app.directive('findProductsToAdd', [
  '$rootScope',
  '$translate',
  'Product',
  'ProductService',
  'SolrDocumentService',
  'ManufacturerService',
  'ModalService',
  'CompanyFactory',
  'ErrorService',
  function (
    $rootScope,
    $translate,
    Product,
    ProductService,
    SolrDocumentService,
    ManufacturerService,
    ModalService,
    CompanyFactory,
    ErrorService
  ) {
    return {
      restrict: 'E',
      templateUrl: require('./findProductsToAdd.html'),
      scope: {
        companyId: '=',
        existedProducts: '=',
        onAdd: '=',
        manufacturers: '=',
        label: '=',
        showCopyButton: '=',
        showPrivate: '='
      },
      link: function (scope, element, attrs) {
        var simpleCatch = ErrorService.simpleCatch;

        scope.productsToLink = [];
        scope.companies = [];
        scope.label = scope.label || 'INVENTORY.FIND_PRODUCTS';
        scope.isLoading = null;
        scope.searchProductName = '';

        scope.searchProductsToLink = function (text) {
          const minSearchTextLength = 3; // min search text length
          if (!text || (text && text.length < minSearchTextLength)) return;


          scope.isLoading = true;

          var searchObj = {
            text: text,
            filter: {
              limit: 50,
              include: {
                relation: 'companies',
                fields: ['id']
              }
            }
          };
          if (scope.showPrivate) {
            searchObj.filter.privateCompanyIdAndPublic = scope.companyId;
          } else {
            searchObj.filter.showPublic = true;
          }
          if (scope.searchByManufacturer !== '*') {
            searchObj.filter.where = {
              manufacturerId: {
                inq: [scope.searchByManufacturer]
              }
            };
          }

          return Product.search(searchObj).$promise.then(function (response) {
            let productsFound = scope.existedProducts
              ? response.products.filter(function (product) {
                  return !scope.existedProducts.some(function (companyProduct) {
                    return companyProduct.id === product.id;
                  });
                })
              : response.products;
            if (scope.companyId) {
              productsFound = ProductService.filterCompanyUnverified(
                productsFound,
                scope.companyId
              );
            }

            scope.productsToLink = productsFound;
            scope.isLoading = false;
          });
        };

        scope.handleSDSModal = SolrDocumentService.openModalOrSDSbyProduct;

        scope.createOrUpdateProduct = function (product) {
          ProductService.copy(product).then(function () {
            product.editable = false;
            scope.searchProductsToLink(scope.searchProductName);
          });
        };

        scope.cancelEdit = function (product) {
          const index = scope.productsToLink.indexOf(product);
          scope.productsToLink.splice(index, 1);
        };

        scope.copyIntoNew = function (product) {
          if (this.productsToLink.some(product => !product.id)) return;

          const newProduct = angular.copy(product, {});
          newProduct.id = undefined;
          newProduct.editable = true;
          newProduct.based = product.id;

          if (typeof newProduct.size === 'string') {
            newProduct.customSize = newProduct.size;
            newProduct.size = null;
          }

          const index = this.productsToLink.indexOf(product);
          this.productsToLink.splice(index, 0, newProduct);
        };

        scope.handleAdd = function (product) {
          scope.isLoading = true;

          var result = scope.onAdd(product);

          if (result && result.then) {
            result
              .then(function () {
                scope.existedProducts && removeProductFromList(product);

                scope.isLoading = false;
              })
              .catch(function () {
                scope.isLoading = false;
              });
          } else {
            scope.existedProducts && removeProductFromList(product);

            scope.isLoading = false;
          }
        };

        scope.handleCreateProductModal = function (name, manufacturerId) {
          return ModalService.open({
            templateUrl: 'createProductModal.html',
            size: 'xlg',
            controller: [
              '$uibModalInstance',
              function ($uibModalInstance) {
                const modal = this;

                modal.product = {
                  name: name,
                  manufacturerId: manufacturerId !== '*' ? manufacturerId : undefined
                };

                modal.onCreated = function (product) {
                  $uibModalInstance.close(product);
                };

                modal.cancel = function () {
                  $uibModalInstance.dismiss();
                };
              }
            ]
          }).then(function (product) {
            scope.handleAdd(product);
          });
        };

        scope.openSupplierInfoModal = function (product, editable = false) {
          return ModalService.open(supplierInfo({ suppliers: product.suppliers, editable })).then(
            suppliers => {
              product.suppliers = suppliers;
            }
          );
        };

        if (scope.manufacturers) {
          initManufacturersDropdown(scope.manufacturers);
        } else {
          ManufacturerService.manufacturersPromise()
            .then(initManufacturersDropdown)
            .catch(simpleCatch);
        }

        const companyId = $rootScope.checkIfUserIs('admin') ? undefined : $rootScope.companyId;
        CompanyFactory.GetCompanies(undefined, companyId)
          .$promise.then(function (allCompanies) {
            scope.companies = allCompanies;
          })
          .catch(simpleCatch);

        function initManufacturersDropdown(manufacturers) {
          $translate('SEARCH.SEARCH_ALL').then(function (translation) {
            scope.manufacturers = manufacturers;
            scope.manufacturersToSearch = angular.extend(
              { '*': { id: '*', name: translation } },
              manufacturers
            );
            scope.searchByManufacturer = scope.manufacturersToSearch['*'].id;
          });
        }

        function removeProductFromList(product) {
          scope.productsToLink = scope.productsToLink.filter(function (productToLink) {
            return productToLink.id !== product.id;
          });
        }
      }
    };
  }
]);
